import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, combineLatestWith, map, share } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceModel } from '../models/device/device-model.model';
import { Device } from '../models/device/device.model';

const apiURL = `${environment.flyLegacyUrl}/api/`;
const deviceModelsApiURL = `${apiURL}device-models/`;

@Injectable({
  providedIn: 'root'
})
export class DeviceCommonService {
  protected deviceModels = this.http.get<DeviceModel[]>(`${deviceModelsApiURL}`).pipe(
    share({
      connector: () => new AsyncSubject(),
      resetOnError: false,
      resetOnComplete: false,
      resetOnRefCountZero: false
    })
  );

  constructor(protected http: HttpClient) {}

  getDeviceModels(): Observable<DeviceModel[]> {
    return this.deviceModels;
  }

  protected linkDeviceModels(devices: Observable<Device[]>): Observable<Device[]> {
    return devices.pipe(
      combineLatestWith(this.deviceModels),
      map(([devicesA, deviceModels]) => {
        for (const device of devicesA) {
          const deviceModel = deviceModels.find((m) => m.id === device.modelID);
          if (deviceModel != null) {
            device.model = deviceModel;
          }
        }
        return devicesA;
      })
    );
  }
}
